/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { LoadingButton } from '@mui/lab';
import { Collapse, Drawer, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import image from 'assets/image';
import { LightTooltip } from 'theme/common-styles';
import SocialLinks from '../socialLinks/SocialLinks';
import { navLinks } from './constant';
import './styles-comp.css';

export default function HomePageHeader() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openCollapse, setCollapseOpen] = useState(false);

    const handleMobileToggle = () => {
        setOpen(!open);
    };

    const handleNavigation = (navigation, event) => {
        event.preventDefault();
        navigate(navigation, { replace: true });
        setOpen(false);
    };

    useEffect(() => {
        const handleScreenResize = () => {
            const size = window.innerWidth <= 1200;
            if (!size) {
                setOpen(false);
            }
        };

        window.addEventListener('resize', handleScreenResize);

        return () => {
            window.removeEventListener('resize', handleScreenResize);
        };
    }, []);

    const location = useLocation();
    const { pathname } = location;

    const isReallyActive = (isActive) => isActive && pathname !== '/';

    const renderNavbarMenu = () =>
        navLinks.map((item, index) => (
            <div className={`mobileNavBar ${item.title.includes('About') ? 'extraMarginForMobile' : ''}`} key={index}>
                <NavLink
                    to={item.url}
                    className={({ isActive }) => (isReallyActive(isActive) ? 'navActiveStyle' : 'navInActiveStyle')}
                    style={({ isActive }) => ({
                        color: isReallyActive(isActive) ? '#489C6C' : '#27272A',
                    })}
                    onClick={() => {
                        setOpen(false);
                        setCollapseOpen(false);
                    }}
                >
                    {item.title}
                </NavLink>

                <Collapse in={openCollapse} timeout="auto" unmountOnExit className="calculatorSubMenu">
                    {item.menuitems.map((items, index) => (
                        <ClickAwayListener onClickAway={() => setCollapseOpen(false)} key={index}>
                            <div className="calSubMenuStyle">
                                <Typography variant="body1">{items.title}</Typography>
                                {items.submenu.map((element, indx) => (
                                    <NavLink
                                        to={element.linkUrl}
                                        key={indx}
                                        style={({ isActive }) => ({
                                            color: isActive ? '#489c6c' : '#27272A',
                                        })}
                                        onClick={(event) => handleNavigation(element.linkUrl, event)}
                                    >
                                        {element.title}
                                    </NavLink>
                                ))}
                            </div>
                        </ClickAwayListener>
                    ))}
                </Collapse>
            </div>
        ));

    return (
        // <Page>
        <div className="header">
            <div className="launch-discount-banner">
                <SocialLinks />
                <LightTooltip
                    style={{ marginLeft: 3 }}
                    title={
                        <p style={{ textAlign: 'center' }}>
                            First Canadian built personal financial app for Canadians, rated as the <br />Best Overall
                            Financial App by{' '}
                            <a
                                href="https://www.moneysense.ca/save/budgeting/the-best-financial-apps-for-canadians/"
                                target="_blank"
                                rel="noreferrer"
                                className="hyperlink-style"
                            >
                                moneysense.ca
                            </a>
                        </p>
                    }
                >
                    {/* <InfoIcon src="/static/linkFinancial/info-icon.svg" alt="" /> */}
                    <img className="flagIcon" alt="Canada Flag" src={image.canadaFlag} />
                </LightTooltip>
            </div>
            <div className="homeHeaderBanner">
                <NavLink to="/">
                    <img src={image.webLogo} alt="hamburger mobile icon" />
                </NavLink>
                <div className="rightButtons">
                    <div className="navigationBar">{renderNavbarMenu()}</div>
                    <div className="mobileDrawer smMobileView">
                        <Drawer
                            anchor="top"
                            open={open}
                            onClose={handleMobileToggle}
                            PaperProps={{
                                sx: {
                                    backgroundColor: '#fff',
                                    marginTop: '75px',
                                    maxHeight: '-webkit-fill-available',
                                },
                            }}
                            className="drawerMobileTab"
                        >
                            <LoadingButton
                                className="loadingButtonStyle2 mobileDrawer"
                                type="submit"
                                variant="outlined"
                                href="/register"
                            >
                                Start your Free Trial
                            </LoadingButton>
                            {renderNavbarMenu()}
                        </Drawer>
                    </div>
                    <LoadingButton className="hamburgerMenu" type="button" onClick={handleMobileToggle}>
                        <img src={image.hamburger} alt="hamburger mobile icon" />
                    </LoadingButton>
                    {open ? (
                        ''
                    ) : (
                        <LoadingButton className="loadingButtonStyle1" type="submit" variant="outlined" href="/login">
                            {' '}
                            {t('login')}
                        </LoadingButton>
                    )}

                    <LoadingButton
                        className="loadingButtonStyle2 hideButton"
                        style={{ marginLeft: '10px' }}
                        type="submit"
                        variant="contained"
                        href="/register"
                    >
                        Start your Free Trial
                    </LoadingButton>
                </div>
            </div>
        </div>
        // </Page>
    );
}
